export enum Levels {
  Zero = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5
};

export enum LevelsCount {
  Zero = 0,
  First = 5,
  Second = 11,
  Third = 21,
  Fourth = 32,
  Fifth = 42
};

export const ElementsInLevels = {
  [Levels.Zero]: LevelsCount.Zero,
  [Levels.First]: LevelsCount.First,
  [Levels.Second]: LevelsCount.Second,
  [Levels.Third]: LevelsCount.Third,
  [Levels.Fourth]: LevelsCount.Fourth,
  [Levels.Fifth]: LevelsCount.Fifth
};

export const LevelsByElements = {
  [LevelsCount.Zero]: Levels.Zero,
  [LevelsCount.First]: Levels.First,
  [LevelsCount.Second]: Levels.Second,
  [LevelsCount.Third]: Levels.Third,
  [LevelsCount.Fourth]: Levels.Fourth,
  [LevelsCount.Fifth]: Levels.Fifth
};