import { Modal, ProgressBronze } from 'components'
import { FC, useEffect, useRef, useState } from 'react'
import { ComponentProps } from './_Awards.types'
import styles from './_Awards.module.scss';

import { useAtomValue } from 'jotai';
import {
  allElementsAtom,
  foundElementsAtom
} from 'atoms';
import { MAX_RECIPES } from 'const';

export const Awards: FC<ComponentProps> = ({
  onClose
}) => {
  const foundElements = useAtomValue(foundElementsAtom);
  const progress = (foundElements.length / MAX_RECIPES) * 100;

  return (
    <Modal
      onClose={ onClose }
      hasCloseButton
      modifiers={['with-blue-block']}
    >
      <div className={styles.root}>
        <div className={styles.header}>

          <ProgressBronze progress={progress} />

          <div className={styles.counter}>
            <p className={styles.counterLabel}>Открытые элементы:</p>
            <p className={styles.counterValue}>{ `${ foundElements.length }/${ MAX_RECIPES }` }</p>
          </div>
        </div>

        <div className={styles.wrap}>
          <ol className={styles.list}>
            <li className={styles.listItem}>Соединяй реагенты, чтобы открывать новые элементы в&nbsp;игре.</li>
            <li className={styles.listItem}>Сделай ставку от&nbsp;1000&nbsp;₽ и&nbsp;коэф. от&nbsp;1.50 на событие из&nbsp;категории &laquo;Футбол&raquo; до&nbsp;30&nbsp;августа 2024.</li>
            <li className={styles.listItem}>Ищи себя в&nbsp;списке победителей&nbsp;8&nbsp;сентября 2024!</li>
          </ol>

          <table className={styles.table}>
            <thead>
              <tr>
                <th>Количество открытых&nbsp;элементов</th>
                <th>Сумма фрибетов</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>42</td>
                <td>1 200 000</td>
              </tr>
              <tr>
                <td>32</td>
                <td>800 000</td>
              </tr>
              <tr>
                <td>21</td>
                <td>500 000</td>
              </tr>
              <tr>
                <td>11</td>
                <td>300 000</td>
              </tr>
              <tr>
                <td>5</td>
                <td>200 000</td>
              </tr>
            </tbody>
          </table>

          <p className={styles.text}>*приз будет разделен на&nbsp;равные части между всеми участниками, прошедшими соответствующее необходимое количество уровней игры. C&nbsp;подробными условиями можно ознакомиться в&nbsp;<a href="/">правилах акции.</a></p>
        </div>
      </div>
    </Modal>
  )
}










